import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import PhotosUploader from "components/Forms/ImageUploader";
import EnhancedQuestionSelector from "./QuestionsSelector";

export default function RevisedTestForm({
  fetchOnlineTests,
  onClose,
}: {
  fetchOnlineTests: () => void;
  onClose: () => void;
}) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    clientId: "",
    expiry: "",
    coverImage: [],
    passingThreshold: 0,
    caseId: "",
    timeLimit: 0,
    selectedQuestions: [],
  });
  const [questions, setQuestions] = useState([]);
  const [clients, setClients] = useState([]);
  const [images, setImages] = useState<any>([]);

  useEffect(() => {
    fetchQuestions();
    fetchClients();
  }, []);

  useEffect(() => {
    setFormData((prev) => ({ ...prev, coverImage: images }));
  }, [images]);

  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/question`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setQuestions(res.data.filter((question: any) => !question.isRemoved));
    } catch (error) {
      console.error("Error fetching questions:", error);
      toast.error("Failed to fetch questions");
    } finally {
      setLoading(false);
    }
  };

  const fetchClients = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/auth/businessClients`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setClients(res.data);
    } catch (error) {
      console.error("Error fetching clients:", error);
      toast.error("Failed to fetch clients");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleQuestionSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    const selectedQuestionObjects = questions.filter((q: any) =>
      selectedOptions.includes(q._id)
    );
    setFormData((prev) => ({
      ...prev,
      selectedQuestions: selectedQuestionObjects,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) {
      toast.error("All fields are required");
      return;
    }
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URI}/onlineTest`,
        {
          ...formData,
          questions: formData.selectedQuestions.map((q: any) => q._id),
          coverImage: formData.coverImage[0],
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      fetchOnlineTests();
      toast.success("Online Test added successfully");
      onClose();
    } catch (error) {
      toast.error("Error adding Online Test");
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    return Object.values(formData).every(
      (value) =>
        value !== "" &&
        value !== 0 &&
        (Array.isArray(value) ? value.length > 0 : true)
    );
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label
          htmlFor="clientId"
          className="block text-sm font-medium text-gray-700"
        >
          Client
        </label>
        <select
          id="clientId"
          name="clientId"
          value={formData.clientId}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          required
        >
          <option value="">Select a client</option>
          {clients.map((client: any) => (
            <option key={client._id} value={client._id}>
              {client.name} - {client.email}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label
          htmlFor="expiry"
          className="block text-sm font-medium text-gray-700"
        >
          Expiry Date
        </label>
        <input
          type="date"
          id="expiry"
          name="expiry"
          value={formData.expiry}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Cover Image
        </label>
        <PhotosUploader
          addedPhotos={images}
          onChange={setImages}
          maxPhotos={1}
        />
      </div>

      <div>
        <label
          htmlFor="passingThreshold"
          className="block text-sm font-medium text-gray-700"
        >
          Passing Threshold (%)
        </label>
        <input
          type="number"
          id="passingThreshold"
          name="passingThreshold"
          value={formData.passingThreshold}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          min="0"
          max="100"
          required
        />
      </div>

      <div>
        <label
          htmlFor="caseId"
          className="block text-sm font-medium text-gray-700"
        >
          Case ID
        </label>
        <input
          type="text"
          id="caseId"
          name="caseId"
          value={formData.caseId}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Enter the case ID"
          required
        />
      </div>

      <div>
        <label
          htmlFor="timeLimit"
          className="block text-sm font-medium text-gray-700"
        >
          Time Limit (minutes)
        </label>
        <input
          type="number"
          id="timeLimit"
          name="timeLimit"
          value={formData.timeLimit}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          min="1"
          required
        />
      </div>

      <div>
        <label
          htmlFor="questions"
          className="block text-sm font-medium text-gray-700"
        >
          Questions
        </label>
        <EnhancedQuestionSelector
          questions={questions}
          selectedQuestions={formData.selectedQuestions}
          onChange={(newSelectedQuestions) =>
            //@ts-ignore
            setFormData((prev) => ({
              ...prev,
              selectedQuestions: newSelectedQuestions,
            }))
          }
        />
      </div>

      <div className="flex justify-end space-x-3">
        <button
          type="button"
          onClick={onClose}
          className="py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={loading}
          className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {loading ? "Adding..." : "Add Test"}
        </button>
      </div>
    </form>
  );
}
